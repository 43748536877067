import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './Button.module.css';

interface ButtonProperties extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  children?: ReactNode;
  reverse?: boolean;
  theme?: 'outline' | 'outlineInverted' | 'square';
}

export const VanErkButton = ({
  children,
  disabled,
  reverse = false,
  theme,
  type = 'button',
  ...rest
}: ButtonProperties) => (
  <button
    disabled={disabled}
    className={getClassNames(theme, reverse)}
    type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
    {...rest}>
    <span className={styles.inner}>{children}</span>
    {theme === 'square' ? undefined : <ButtonArrow />}
  </button>
);

const getClassNames = (theme: ButtonProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.vanErk, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.square]: theme === 'square',
  });

const ButtonArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 42"
    preserveAspectRatio="xMinYMin meet"
    strokeWidth={1}>
    <path d="M0 .5h-.5v41h13.965c.908 0 1.796-.269 2.549-.772a4.51 4.51 0 0 0 1.676-2.054l6.483-16a4.452 4.452 0 0 0 0-3.348l-6.483-16a4.51 4.51 0 0 0-1.676-2.054A4.589 4.589 0 0 0 13.464.5H0Z" />
  </svg>
);
